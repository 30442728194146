*{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.Icon--sm {
    height: 16px;
}

svg:not(:root) {
    overflow: hidden;
}

.block-info-paid {
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.block-info-paid-check {
    background-color: #24b47e;
    padding: 8px;
    right: -9px;
    top: 64px;
    border-radius: 40px;
    width: 32px;
    height: 32px;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    align-self: center;
}

.block-info-paid-title {
    flex-wrap: wrap;
    align-self: center;
    margin: 20px 0 10px;
}

.block-info-paid-title span {
    font-weight: 400;
    font-size: 20px;
    color: #16724f;
}

.block-info-paid path {
    fill: #fff;
}

.block-info-paid-pdf {
    flex-wrap: wrap;
    align-self: center;
    margin: 20px 0 20px;
}

.block-info-paid-pdf button {
    text-decoration: none;
    padding: 7px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #3c4257;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    cursor: pointer;
}

.block-info-paid-pdf button .Icon--sm {
    height: 12px;
}

.paid-pdf-popover-content a {
    float: left;
    clear: left;
    display:inline;
    line-height: 25px;
}

.ant-popover-inner {
    border-radius: 5px;
}

.ant-popover-inner-content {
    min-height: 72px;
    min-width: 160px;
}
