/*tab-bank*/

form {
  width: 100%;
}

.hosted-fields-inputs {
  display: flex;
  justify-content: space-between;
}

input::placeholder {
  color: #515e80;
}

input::-webkit-input-placeholder {
  opacity: 1;
  color: #aab7c4;
}

input::-moz-placeholder {
  opacity: 1;
  color: #aab7c4;
}

input:-ms-input-placeholder {
  opacity: 1;
  color: #aab7c4;
}

input::placeholder {
  opacity: 1;
  color: #aab7c4;
}

#routing-number {
  padding: 9px 12px 8px;
}

.hosted-field-container {
  flex: 1 1;
  height: 45px;
  padding: 8px 12px 8px 14px;
  margin-left: 8px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #eee;
  background-color: #fff;
  transition: box-shadow .1s ease-in,border .1s ease-in;
  max-width: 100%;
  min-width: 0;
}

.hosted-field-container:first-of-type {
  margin-left: 0;
}

.hosted-field-container input {
  border: none;
  font-size: 16px;
  color: #32325d;
  width: 100%;
}
