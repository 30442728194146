/*tab-card*/

.card-element-container {
  height: 45px;
  padding: 13px 12px 8px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #eee;
  background-color: #fff;
  transition: box-shadow .1s ease-in,border .1s ease-in;
}
