/*card header*/


.header-box {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-box-inner {
  flex: 1 1 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.card-header-logo-img {
  align-self: center;
  border: 0;
  max-height: 200px;
  max-width: 600px;
  margin: 10px 0 10px 0;
}

.card-header-title {
  margin-bottom: 4px;
  margin-top: 20px;
  text-align: center;
}

.card-header-title-inner {
  pointer-events: auto;
  white-space: normal;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 28px;
  font-weight: 400;
  font-size: 20px;
  color: #1a1f36;
  margin-bottom: 22px;
}

.card-header-customer-name ,
.card-header-invoice-number {
  margin-top: 4px;
}

.card-header-customer-name span,
.card-header-invoice-number span {
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #697386;
}
