/*payment card*/

.card-root {
  overflow: hidden;
  border-radius: 4px;
  box-shadow:0 0 0 1px #e3e8ee;
  background-color: #fff;
}

.content-header {
  background-color: #fff;
  box-shadow: inset 0 -1px #e3e8ee;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.content-header:first-child{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

