* {
  box-sizing: border-box;
  word-wrap: break-word;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
}

blockquote, body, button, dd, div, dl, dt, fieldset, form, input, legend, li, ol, p, td, textarea, th, ul {
  margin: 0;
  outline: 0;
  padding: 0;
}

.hosted-invoice-root {}

.hosted-invoice-world-root {
  height: 100%;
  width: 100%;
  min-height: 100%;
}

.box-root {
  box-sizing: border-box;
}

.flex-flex {
  display: flex;
  display: -ms-flexbox;
}

.padding-top--32 {
  padding-top: 32px;
}

.flex-justify-content--center {
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-flex {
  display: -ms-flexbox;
  display: flex;
}

.hosted-invoice-world-main-pane {
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  min-height: 100%;
}

.hosted-invoice-world-content-pane {
  width: 640px;
}

.flex-wrap--nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-justify-content--flex-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.margin-left--32 {
  margin-left: 32px;
}

.margin-top--32 {
  margin-top: 32px;
}

.margin-horizontal--32 {
  margin-right: 32px;
  margin-left: 32px;
}

.margin-bottom--32 {
  margin-bottom: 32px;
}

.margin-left--8{
  margin-left: 8px;
}

.margin-top--8 {
  margin-top: 8px;
}

.box-hide-if-empty:empty {
  display: none;
}

.text-color--default {
  color: #3c4257;
}

.text-align--center {
  text-align: center;
}

.text-font-size--14 {
  font-size: 14px;
}

.text-font-weight--medium {
  font-weight: 500;
}

.text-line-height--20 {
  line-height: 20px;
}

.text-numeric-spacing--proportional {
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
}

.text-typeface--base {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
}

.text-typeface--base .inline-link, .text-typeface--upper .inline-link {
  font-weight: 500;
}

.text-wrap--wrap {
  white-space: normal;
}

.text-display--block {
  display: block;
}

.button--color--white .shortcut, .button-link .text-color--gray .shortcut,.shortcut--override--gray {
  color: #697386;
  background: #e3e8ee;
}

.button--color--white .shortcut--separator, .button-link .text-color--gray .shortcut--separator {
  color: #a3acb9;
}

.text-color--gray {
  color: #697386;
}

.text-font-weight--regular {
  font-weight: 400;
}

.padding-bottom--20{
  padding-bottom:20px
}

.ant-spin-nested-loading {
  height: 100%;
}