/*tabs*/

.block-pay-invoice-tab-header {
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 32px;
  margin: 0;
  position: relative;
}

.block-pay-invoice-tab-container {
  padding: 8px 42px 32px;
  margin: 0;
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

.tab-header {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  min-width: 0;
  max-width: 100%;
  margin-left: 8px;
  padding: 10px 12px 8px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #eee;
  background-color: #fff;
  transition: box-shadow .1s ease-in,border .1s ease-in;

}

.tab-header:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px 2px #eee;
  cursor: pointer;
}

.tab-header:first-of-type {
  margin-left: 0;
}

.tab-header-icon, .tab-header-title {
  text-align: center;
}

.tab-header-icon svg, .tab-header-icon svg g {
  height: 16px;
  fill: #000;
}
