/*card details*/

.card-details-container, .card-details-block-grid, .card-details-block-foot {
  -webkit-box-shadow: inset 0 1px #e3e8ee;
  -moz-box-shadow: inset 0 1px #e3e8ee;
  box-shadow: inset 0 1px #e3e8ee;
}

.card-details-block-grid {
  padding: 32px;
}

.card-details-block-grid-memo {
  margin-left: -16px;
  margin-top: -16px;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -ms-flexbox;
  display: flex;
  pointer-events: none;
  padding-bottom: 32px;
}

.card-details-block-grid-container {
  background-color: #fff;
}

.invoice-memo-block, .invoice-memo-block-icon {
  pointer-events: auto;
  margin-left: 16px;
  margin-top: 16px;
}

.invoice-memo-block-icon {
  color: #4f566b;
  display: flex;
}

.invoice-memo-block-icon svg {
  fill: currentColor;
  color: #4f566b;
  pointer-events: auto;
}

.invoice-memo-block-icon svg:not(:root) {
  overflow: hidden;
}

.invoice-memo-block span {
  white-space: pre-wrap;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  display: inline;
  color: #3c4257;
}

.card-details-block-grid-container {
  background-color: #fff;
}

.block-grid-header-row {
  grid-template-columns: 7fr 1fr 2fr 2fr;
  padding-bottom: 16px;
  box-shadow: inset 0 -1px #e3e8ee;
  box-sizing: border-box;
  display: -ms-grid;
  display: grid;
  grid-column-gap: 10px;
}

.block-grid-header-cell {
  pointer-events: auto;
}

.block-grid-header-cell > :first-child {
  -ms-grid-column: 1;
}

.block-grid-header-cell span,
.block-grid-header-cell-flex span {
  white-space: normal;
  text-transform: uppercase;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 20px;
  font-weight: 500;
  font-size: 13px;
  color: #1a1f36;
  pointer-events: auto;
}

.block-grid-header-cell-flex {
  pointer-events: auto;
  box-sizing: border-box;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
}

.block-grid-header-cell-flex > :nth-child(2) {
  -ms-grid-column: 3;
}

.block-grid-header-cell-flex > :nth-child(3) {
  -ms-grid-column: 5;
}

.block-grid-header-cell-flex > :nth-child(4) {
  -ms-grid-column: 7;
}

.block-grid-items {
  -webkit-box-shadow: inset 0 -1px #e3e8ee;
  -moz-box-shadow: inset 0 -1px #e3e8ee;
  box-shadow: inset 0 -1px #e3e8ee;
  pointer-events: auto;
}

.block-grid-items-row {
  pointer-events: auto;
  grid-template-columns: 7fr 1fr 2fr 2fr;
  padding-top: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
  display: -ms-grid;
  display: grid;
  grid-column-gap: 10px;
}

.block-grid-items-row.block-grid-totals-items-row {
  grid-template-columns: 3fr 0 7fr 2fr;
}

.block-grid-totals-pdf-button {
  text-decoration: none;
  padding: 7px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #3c4257;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
}

.block-grid-totals-pdf-button .Icon--sm {
  height: 12px;
}

.block-grid-item-cell {

}

.block-grid-item-cell > :first-child {
  -ms-grid-column: 1;
}

.block-grid-item-cell > :nth-child(2) {
  -ms-grid-column: 3;
}

.block-grid-item-cell > :nth-child(3) {
  -ms-grid-column: 5;
}

.block-grid-item-cell > :nth-child(4) {
  -ms-grid-column: 7;
}

.block-grid-item-span {
  white-space: normal;
  word-break: break-word;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  display: inline;
  color: #3c4257;
}

.cell-flex {
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
}

.card-details-block-foot {
  pointer-events: none;
  padding: 20px 32px 20px 32px;
}

.card-details-block-foot-inner {
  margin-left: -16px;
  margin-top: -16px;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
  padding-left: 16px;
  padding-top: 16px;
  text-align: center;
}

.card-details-block-foot-inner span {
  white-space: normal;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #697386;
  pointer-events: auto;
}

.card-details-block-foot-inner a {
  color: #5469d4;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.card-details-custom-footer {
  padding: 16px 0 0;
}
