/*card content*/
*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.content-block {
  background-color: #f7fafc;
}

.block-info {
  padding: 32px 32px 12px 32px;
}

.block-info-inner {
  margin-left: -8px;
  margin-top: -8px;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  flex-direction: column;
  display: flex;
}

.block-info-title, .block-info-description {
  pointer-events: auto;
  margin-left: 8px;
  margin-top: 8px;
  text-align: center;
}

.block-info-title span {
  white-space: normal;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 28px;
  font-weight: 400;
  font-size: 20px;
  color: #1a1f36;
}

.block-info-description span {
  white-space: normal;
  font-feature-settings: "pnum";
  font-variant: proportional-nums;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  display: block;
  color: #697386;
}
