.pay-invoice-button {
    margin-top: 18px;
}
.pay-invoice-button .ant-btn {
    width: 100% !important;
    height: 45px;
    cursor: pointer;
    transition: box-shadow .15s ease-in,border .15s ease-in;
    font-size: 16px;
    font-weight: 600;
}

.pay-invoice-button .ant-btn:focus, .pay-invoice-button .ant-btn:hover {
    box-shadow: 0 2px 5px 0 rgba(84,105,212,0.3);
}
